<template>
  <div class="body">
    <!-- 面包屑组件 -->
    <div>
      <crumbs />
    </div>
    <div class="card">
      <el-card class="box-card">
        <div
          style="border-bottom: 1px solid #bfbfbf; padding-bottom: 10px;"
          class="about_select"
        >
          <el-button type="danger" size="mini" plain @click="load(1, 0)"
            >未审核</el-button
          >
          <el-button type="warning" size="mini" plain @click="load(1, 1)"
            >已审核</el-button
          >
        </div>
        <el-table
          :data="list"
          stripe
          style="width: 100%;"
          v-loading="loading"
          element-loading-text="拼命加载中"
        >
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="username" label="提交者"></el-table-column>
          <el-table-column prop="type_name" label="类型"></el-table-column>
          <el-table-column prop="state" label="审核状态">
            <template slot-scope="scope">
              <div v-if="scope.row.state == 6">未审核</div>
              <div v-else-if="scope.row.state == 7">审核通过</div>
              <div v-else>
                审核不通过
                <div class="ablout">原因：{{scope.row.examine_err}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="操作">
            <template slot-scope="scope">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  @click="examine(scope.row.id, scope.row.state)"
                  >{{
                    scope.row.state == 6
                      ? "去审核"
                      : scope.row.state == 7
                      ? "审核通过"
                      : "审核不通过"
                  }}</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 弹窗 -->
        <el-dialog
          title="提示"
          :visible.sync="off"
          width="70%"
          modal
          :close-on-click-modal="false"
        >
          <div class="ablout_image">
            <el-image
              :preview-src-list="imgUrl"
              :src="item"
              :z-index="20000"
              v-for="(item, index) in imgUrl"
              :key="index"
            ></el-image>
          </div>
          <el-form label-width="80px">
            <el-form-item label="审核结果">
              <el-radio-group v-model="label">
                <el-radio label="1" border :disabled="!state">通过</el-radio>
                <el-radio label="2" border :disabled="!state">不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="原因" v-if="label == 2">
              <el-input
                placeholder="请输入不通过原因"
                v-model.trim="label_value"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="button" v-if="state">
            <el-button @click="off = false">取 消</el-button>
            <el-button type="primary" @click="determine" :disabled="!state"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </el-card>
    </div>
    <div class="paginations" :style="{ width: $store.state.widthStyle }">
      <Pagenation
        :size="30"
        :total="total"
        v-on:getPage="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Pagenation from "../components/pagenation";
import { Base64 } from "js-base64";
export default {
  components: {
    Pagenation,
  },
  data() {
    return {
      // 数据
      list: [],
      //loading
      loading: true,
      //弹窗开关
      off: false,
      imgUrl: [],
      state: false,
      label: "1",
      label_value: "",
      id: 0,
      total: 0,
      type: 0,
      pagenumber:1
    };
  },
  mounted() {
    this.load(1, 0);
  },
  methods: {
    // 进入页面请求(页数,类型)
    load(pageNumber, type) {
      this.$axios
        .post("/get_voucher_list", {
          pagenumber: pageNumber,
          type,
        })
        .then((res) => {
          // console.log(res);
          this.total = res.count;
          this.list = res.data;
          this.loading = false;
        });
      this.type = type;
    },
    // 点击操作按钮
    examine(id, state) {
      this.imgUrl = [];
      this.list.forEach((val1) => {
        if (val1.id == id) {
          if (!val1.voucher_address) {
            return;
          }
          val1.voucher_address.split(",").forEach((val2) => {
            this.imgUrl.push(this.$store.state.BaseUrl + val2.split("./")[1]);
          });
        }
      });
      this.id = id;
      this.state = state == 6 ? true : false;
      this.off = true;
    },
    // 确定通过||确定不通过
    determine() {
      if(this.label == 2) {
        if(this.label_value == ''){
          alert('请输入不通过原因!')
        }
      }
      this.$axios
        .post("/to_examine", {
          reason: this.label_value ? Base64.encode(this.label_value) : "",
          audit: this.label,
          id: this.id,
        })
        .then((res) => {
          this.off = false
          this.load(this.pagenumber,this.type);
        });
    },
    //分页
    handleCurrentChange(val) {
      this.pagenumber = val;
      this.load(val, this.type);
    },
  },
};
</script>

<style scoped>
.el-button--success {
  background: #29d49e;
  border: #29d49e;
}
.el-button--success:hover {
  background: #1d916c;
  border: #1d916c;
}
.add_roles {
  margin: 10px 0;
}
.paginations {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 93.6%;
  background: #fff;
}
.input {
  padding: 10px;
}
.input > .el-input {
  width: 50%;
}
.ablout {
  font-size: 14px;
  color: #f00;
}
.ablout_image {
  /* height: 220px; */
  margin: 10px;
  text-align: center;
}
.ablout_image .el-image {
  width: 10%;
  height: 100px;
  margin: 0 80px;
  padding: 20px;
}
@media screen and (max-width: 550px) {
  .ablout_image img {
    width: 100%;
    height: 200px;
    margin: 0;
  }
  .ablout_image {
    height: 400px;
    margin: 10px;
    text-align: center;
  }
}
.button {
  display: flex;
  justify-content: center;
}
</style>
